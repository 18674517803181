<template>
  <b-card
    no-body
    class="p-2"
  >
    <h3 class="mb-2">
      {{ $t('humanResources.workStation.edit.editWorkStation') }}
    </h3>
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-row>
          <!-- name work place field -->
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="{ errors }"
              name="work station"
              rules="required"
            >
              <b-form-group
                :label="$t('humanResources.workStation.columns.workStation')"
                :state="errors.length > 0 ? false:null"
              >
                <b-form-input
                  v-model="workStation.nombre"
                />
                <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!-- Description Field -->
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="{ errors }"
              name="Description"
              rules="required"
            >
              <b-form-group
                :label="$t('humanResources.workStation.columns.description')"
                :state="errors.length > 0 ? false:null"
              >
                <b-form-input
                  v-model="workStation.descripcion"
                />
                <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!--work area field--->
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="validationContext"
              name="work area"
              rules="required"
            >
              <b-form-group
                :label="$t('humanResources.workStation.columns.workArea')"
              >
                <v-select
                  v-model="workStation.areaTrabajoId"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :clearable="false"
                  label="texto"
                  :reduce="option => option.id"
                  :options="workAreas"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!--profile activities field--->
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="validationContext"
              name="profile activities"
              rules="required"
            >
              <b-form-group
                :label="$t('humanResources.workStation.columns.profileActivities')"
              >
                <b-form-textarea
                  v-model="workStation.perfilActividades"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!--profile age field--->
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="validationContext"
              name="profile age"
              rules="required"
            >
              <b-form-group
                :label="$t('humanResources.workStation.columns.profileAge')"
              >
                <b-form-input
                  v-model="workStation.perfilEdad"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!--profile Scholarship field--->
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="{ errors }"
              name="profile scholarship"
              rules="required"
            >
              <b-form-group
                :label="$t('humanResources.workStation.columns.profileScholarship')"
                :state="errors.length > 0 ? false:null"
              >
                <v-select
                  v-model="workStation.perfilEscolaridadId"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :clearable="false"
                  label="texto"
                  :reduce="option => option.id"
                  :options="scholarships"
                />
                <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!--profile experience required field--->
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="validationContext"
              name="experience required"
              rules="required"
            >
              <b-form-group
                :label="$t('humanResources.workStation.columns.profileRequiredExperience')"
              >
                <b-form-textarea
                  v-model="workStation.perfilExperienciaRequerida"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!--profile gender field--->
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="{ errors }"
              name="profile gender"
              rules="required"
            >
              <b-form-group
                :label="$t('humanResources.workStation.columns.profileGender')"
                :state="errors.length > 0 ? false:null"
              >
                <v-select
                  v-model="workStation.perfilGeneroId"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :clearable="false"
                  label="texto"
                  :reduce="option => option.id"
                  :options="gender"
                />
                <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!--profile working hours field--->
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="validationContext"
              name="working hours"
              rules="required"
            >
              <b-form-group
                :label="$t('humanResources.workStation.columns.profileSchedule')"
              >
                <b-form-textarea
                  v-model="workStation.perfilHorario"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!--profile salary field--->
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="validationContext"
              name="salary"
              rules="required"
            >
              <b-form-group
                :label="$t('humanResources.workStation.columns.profileSalary')"
              >
                <b-form-input
                  v-model="workStation.perfilSueldo"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-button
          variant="primary"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          type="submit"
        >
          {{ $t('Lists.saveChanges') }}
        </b-button>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BCard,
  BForm,
  BRow,
  BCol,
  BFormInput,
  BFormGroup,
  BFormTextarea,

  BFormInvalidFeedback,
  BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import humanResources from '@/services/humanresources.service'
import { ref } from '@vue/composition-api'
import vSelect from 'vue-select'
import optionService from '@/services/option.service'
import router from '@/router'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'

export default {
  components: {
    BCard,
    BForm,
    BRow,
    BCol,
    BFormInput,
    BFormInvalidFeedback,
    BFormGroup,
    BButton,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  setup() {
    /* SERVICES */
    const {
      updateWorkStation,
      fetchWorkStation,
      fetchWorkAreaOptions,
      fetchScholarshipOptions,
    } = humanResources()
    const {
      fetchOptions,
    } = optionService()
    // Data
    const workAreas = ref([])
    const scholarships = ref([])
    const gender = ref([])
    const workStation = ref([])
    fetchWorkStation(router.currentRoute.params, data => {
      workStation.value = data
    })
    /* Events */
    const onSubmit = () => {
      updateWorkStation(workStation.value)
        .then(() => {
          router.push({ name: 'apps-human-resources-work-station-list' })
        })
    }
    fetchWorkAreaOptions(data => {
      workAreas.value = data
    })
    fetchScholarshipOptions(data => {
      scholarships.value = data
    })
    fetchOptions({ nombreDominio: 'Genero' }, data => { gender.value = data })
    /* Validations */
    const fetchScholarshipData = ref(JSON.parse(JSON.stringify(workStation.value)))
    const resetclienteData = () => {
      fetchScholarshipData.value = JSON.parse(JSON.stringify(workStation))
    }
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetclienteData)
    return {
      // Data
      workStation,
      workAreas,
      scholarships,
      gender,
      /* Events */
      onSubmit,
      // Validations
      required,
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style>

</style>
